
import { Component, Vue } from 'vue-property-decorator'
import { SumecList } from '../../types/sumec.d'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: ''
  }

  private runStateList = {
    0: '割草',
    1: '待机',
    2: '回仓',
    3: '休眠等待',
    4: '充电',
    5: '故障'
  }

  private loading = false
  private dataList: Array<SumecList> = []
  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SumecList> }>(this.$apis.sumec.selectSumecDevicePage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onDetail (id: string) {
    // 设备详情
    this.$router.push({
      name: 'sumecDeviceDetailList',
      params: { projectId: this.projectId, deviceId: id }
    })
  }
}
